.app {
  display: flex;
  height: 100%;
  width: 100%;

  menu {
    min-width: 260px;
    padding: 10px;
    margin: 0;

    ul {
      list-style-type: none;
      padding: 20px;
      background: #dfdfdf;
      border-radius: 20px;
    }

    li {
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .page-wrapper {
    flex: 1;
    padding: 20px;
  }
}

.up-icon {
    background-image: url(components/cas/Images/arrow-up.svg);
    background-repeat: no-repeat;
}

.down-icon {
    background-image: url(components/cas/Images/arrow-down.svg);
    background-repeat: no-repeat;
}

.card {
    background: #F1F7F3;
    margin-bottom: 20px;
    overflow: hidden;
}

.chartjs {
  .card {
    display: flex;

    > canvas {
      flex: 1;
    }
  }
}

.cas {
    .pageBreak {
        page-break-before: always;
    }

    .pbAfter {
        page-break-after: always;
    }


    .headerLogo {
        margin-top: 20px;
        width: 149px;
        height: 67px;
    }

    .percentageLogo {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
    }

    .percentageText {
        margin-top: 10px;
    }

    .gras-table {
        th {
            border-bottom: 1px solid black;
        }

        tr:nth-child(even) {
            background-color: #F3F6F8;
        }
    }

    .header-row {
        border-bottom: 2px solid #158A42;
        margin-bottom: 30px;
    }

    .header-col-btn {
        text-align: right;
    }

    .header-btn {
        margin-top: 50px;
        font-size: large;
        width: 150px;
        background-color: #158A42;
        color: white;
        border-radius: 7px;
        border-color: #158A42;
        padding: 5px 20px 5px 20px;
    }

    .header {
        margin-top: 37px;

        h1, h1-download {
            color: #158A42 !important;
        }
    }

    .card {
        height: 100% !important;
        margin-bottom: 20px;
        overflow: visible;
    }

    .c-title {
        margin: 10px;
    }

    .c-row {
        margin-left: 0px;
        vertical-align: bottom !important;
        font-weight: bold;
    }

    .percent {
        text-align: right;
    }

    .gras {
        background: #F1F7F3 !important;

        h1 {
            color: #158A42 !important;
        }
    }

    .sun {
        background: #FCF8F0 !important;

        h1 {
            color: #FFB400 !important;
        }
    }

    .water {
        background: #F3F6F8 !important;

        h1 {
            color: #064789 !important;
        }
    }

    .earth {
        background: #F5F2F1 !important;

        h1 {
            color: #612812 !important;
        }
    }
}

.print {
  position: absolute;
  top: -99999px;
}

@media screen and (max-width: 575px) {
  .app {
    menu {
      display: none;
    }
  }
}

@media print {
  @page {
    size: A4 portrait;
  }

  .print {
    position: relative;
    top: 0;
  }

  .screen {
    display: none;
  }

  body {
    font-size: 8pt !important;
    line-height: 1.3;
    background: #fff !important;
    width: 100% !important;
    color: #000;
  }

  menu {
    display: none;
  }

  h1 {
    font-size: 14pt !important;
  }

  h2, h3, h4, h5, h6 {
    font-size: 10pt !important;
  }

  .card {
    overflow: hidden !important;
  }

    .pageBreak {
        page-break-before: always;
    }

    .pbAfter {
        page-break-after: always;
    }
}


.print-download {
    position: relative;
    top: 0;
}

.screen-download {
    display: none;
}

.bclass-download {
    font-size: 8pt !important;
    background: #fff !important;
    color: #000;
    width: 210mm;
    height: 297mm;
    margin: 11mm 11mm 11mm 11mm;
}

menu-download {
    display: none;
}

.hfont1-download {
    font-size: 14pt !important;
}

.hfont2-download, .hfont3-download, .hfont4-download, .hfont5-download, .hfont6-download {
    font-size: 10pt !important;
}

.card-download {
    overflow: hidden !important;
}

.faq-number {
    color: black !important;
    font-weight: 100 !important;
    font-size: small !important;
}
